<template>
  <div class="page-not-found">
    <div class="page-not-found__container _container">
      <div class="page-not-found__header">
        <h2 class="page-not-found__title title">{{$t("page_not_found.title")}}</h2>
      </div>
      <div class="page-not-found__body">
        <div class="page-not-found__subtitle section__title">
          <div class="vertical-line"></div>
          <div class="page-not-found__subtitle">
            {{$t("page_not_found.subtitle")}}
          </div>
        </div>
        <p class="page-not-found__text">
          {{$t("page_not_found.text")}}
        </p>
        <router-link :to="{ path: '/' }" class="page-not-found__btn">
         {{$t("page_not_found.link")}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-not-found {
  &__container {
    padding-top: 110px;
    padding-bottom: 50px;
    @include for-tablet-portrait-up {
      padding-top: 132px;
      padding-bottom: 60px;
    }
    @include for-tablet-landscape-up {
      padding-top: 142px;
      padding-bottom: 70px;
    }
    @include for-desktop-up {
      padding-top: 132px;
      padding-bottom: 106px;
    }
  }
  &__btn {
    font-family: "Manrope", sans-serif;
    text-decoration: none;
    width: 182px;
    height: 42px;
    @include P4();
    @include button();
    margin-top: 40px;
    @include for-tablet-portrait-up {
      margin-top: 50px;
    }
    @include for-tablet-landscape-up {
      margin-top: 70px;
      width: 200px;
      height: 46px;
      @include P3();
    }
    @include for-desktop-up {
      margin-top: 80px;
    }
  }
  &__title {
    font-family: "Merriweather", serif;
    @include H4-semi-bold();
    padding-bottom: 40px;
    color: $main-color;
    @include for-tablet-landscape-up {
      @include H2-semi-bold();
    }
    @include for-desktop-up {
      @include H1-semi-bold();
      padding-bottom: 60px;
    }
  }
  &__text {
    font-family: "Manrope", sans-serif;
    @include P4();
    color: $super-dark-gray-color;
    margin-top: 18px;
    @include for-tablet-portrait-up{
      margin-top: 16px;
    }
    @include for-tablet-landscape-up {
      @include P3();
      margin-top: 11px;
    }
    @include for-desktop-up {
      @include P2();
    }
  }
}
</style>
